import '@/global';

const sections = [
    'header-navigation',
    'hero-comparison-slider',
    'contact-form',
    'features-slider',
    'algo-app-tabs',
    'why-algobuilder',
    'faq',
    'checkout-session',
    'switch-markets-balance'
];

// import code by section presence
// section should have data-js-import="name" attribute
sections
    .filter((name) => document.querySelector(`section[data-js-import="${name}"]`))
    .forEach((name) => {
        import(
            `@/sections/${name}`
            );
    });

